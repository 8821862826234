import i18n from '@/utils/localization';
import { getConfig } from '@/utils/configuration';

const typeInfo = {
  video: {
    icon: ['fas', 'video'],
    text: i18n.t('GENERAL.LABEL.VIDEO'),
  },
  application: {
    icon: ['fas', 'file-alt'],
    text: i18n.t('GENERAL.LABEL.FILE'),
  },
  image: {
    icon: ['fas', 'image'],
    text: i18n.t('GENERAL.LABEL.IMAGE'),
  },
  pdf: {
    icon: ['fas', 'file-pdf'],
    text: i18n.t('GENERAL.LABEL.PDF'),
  },
  docx: {
    icon: ['fas', 'file-word'],
    text: i18n.t('GENERAL.LABEL.DOCX'),
  },
  audio: {
    icon: ['fas', 'music'],
    text: i18n.t('GENERAL.LABEL.AUDIO'),
  },
};

function getMediaInfoType(media: MediaResponse) {
  let infoKey;
  const { file_content, file_type } = media;

  const docxFileTypes = [
    'vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
  ].join(' ');

  if (file_content === 'application') {
    infoKey = file_type;
    if (docxFileTypes.includes(file_type)) infoKey = 'docx';
  } else infoKey = file_content;
  return infoKey;
}

const abbreviations = {
  hours: i18n.t('GENERAL.LABEL.HOURS_ABBREVIATION'),
  minutes: i18n.t('GENERAL.LABEL.MINUTES_ABBREVIATION'),
  seconds: i18n.t('GENERAL.LABEL.SECONDS_ABBREVIATION'),
};

function getAbbreviation(value, key) {
  return value.toString() + abbreviations[key];
}

function formatVideoLengthFromSeconds(s) {
  if (!s) return '';
  const seconds = s % 60; // Seconds that cannot be written in minutes
  const secondsInMinutes = (s - seconds) / 60; // Gives the seconds that COULD be given in minutes
  const minutes = secondsInMinutes % 60; // Minutes that cannot be written in hours
  const hours = (secondsInMinutes - minutes) / 60;
  const minutesRound = minutes + Math.round(seconds / 60);

  if (hours) {
    const hoursDisplay = getAbbreviation(hours, 'hours');
    if (minutesRound) {
      const minutesDisplay = getAbbreviation(minutesRound, 'minutes');
      return `${hoursDisplay} ${minutesDisplay}`;
    }
    return hoursDisplay;
  } else {
    if (minutes) {
      return getAbbreviation(minutesRound, 'minutes');
    } else {
      return getAbbreviation(seconds, 'seconds');
    }
  }
}

function getDefaultThumbnailForMediaType(mediaType: string) {
  switch (mediaType) {
    case 'video':
      return '/assets/images/empty-media-thumbnails/video.svg';
    case 'audio':
      return '/assets/images/empty-media-thumbnails/audio.svg';
    case 'application':
      return '/assets/images/empty-media-thumbnails/text.svg';
    case 'typeform':
      return '/assets/images/empty-media-thumbnails/text.svg';
    case 'image':
      return '/assets/images/empty-media-thumbnails/image.svg';
    case 'module':
      return '/assets/images/empty-media-thumbnails/module.svg';
    case 'text':
      return '/assets/images/empty-media-thumbnails/text.svg';
    default:
      return '';
  }
}

function getDefaultThumbnailForProductType(productType: string) {
  switch (productType) {
    case 'private_space':
      return '/assets/images/product/private-space.svg';
    case 'media_library':
      return getConfig()['product-membership-img'];
    case 'course':
      return '/assets/images/product/course.svg';
    case 'bundle':
      return '/assets/images/product/bundle.svg';
    case 'event_only':
      return '/assets/images/product/in-person-only.svg';
    case 'private_coaching':
      return '/assets/images/product/private-coaching.svg';
    case 'digital_download':
      return '/assets/images/product/digital-download.svg';
    case 'punch_card':
      return getConfig()['product-classcard-img'];
    default:
      return '';
  }
}

function getMediaTypeLabel(mediaType: string) {
  switch (mediaType) {
    case 'video':
      return 'GENERAL.LABEL.VIDEO';
    case 'audio':
      return 'GENERAL.LABEL.AUDIO';
    case 'application':
      return 'GENERAL.LABEL.DOCUMENT';
    case 'text':
      return 'GENERAL.LABEL.RICH_CONTENT';
    case 'image':
      return 'GENERAL.LABEL.IMAGE';
    case 'typeform':
      return 'GENERAL.LABEL.TEXT_HTML';
    default:
      return 'GENERAL.LABEL.UNCATEGORIZED';
  }
}

function getMediaTypeIconClasses(mediaType: string) {
  switch (mediaType) {
    case 'video':
      return 'fal fa-video';
    case 'audio':
      return 'fal fa-music';
    case 'application':
      return 'fal fa-file-alt';
    case 'image':
      return 'fal fa-image';
    default:
      return '';
  }
}

export {
  typeInfo,
  getMediaInfoType,
  formatVideoLengthFromSeconds,
  getDefaultThumbnailForMediaType,
  getDefaultThumbnailForProductType,
  getMediaTypeLabel,
  getMediaTypeIconClasses
};
